<template>
    <div class="detail">
        <NavBar title="清单详情" left-text="返回" left-arrow fixed right-text="签收记录" @click-left="onClickLeft"
            @click-right="onClickRight" />
        <div class="info">
            <div>楼盘：{{ detail.title }}</div>
            <div>房号：{{ detail.aptno }}</div>
            <div>地址：{{ detail.address }}</div>
        </div>
        <div class="list-detail" style="margin-bottom:1.2rem;"
            v-if="detail.times && detail.times.length > 0">
            <div class="list-detail-header">
                <div class="list-item" style="flex:0.2;"></div>
                <div class="check" style="flex:0.8;">配送时间</div>
            </div>
            <div class="time-item" style="border-bottom: 1px solid #ddd;">
                <div class="item-left" style="display:flex;align-items:center;">
                    <div style="flex:0.2;">
                        <div>家具</div>
                        <Button v-if="fileList.furniture.length" type="info" :loading=buttonLoading
                            @click="downloadAttach('furniture')" size="mini">下载清单({{
                                fileList.furniture.length }})</Button>
                    </div>

                    <div
                        style="flex:0.8;line-height:1.6rem;border-left:1px solid #ccc;padding:0.4rem 0">
                        <div v-for="(item) in detail.times" :key="item.id">
                            <div v-if="item.type == 1">
                                {{ item.delivery_time }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="list-detail" style="margin-bottom:1.2rem;"
            v-if="detail.times && detail.times.length > 0">
            <div class="list-detail-header">
                <div class="list-item" style="flex:0.2;"></div>
                <div class="check" style="flex:0.4;">测量时间</div>
                <div class="check" style="flex:0.4;">安装时间</div>
            </div>
            <div class="time-item" style="border-bottom: 1px solid #ddd;">
                <div class="item-left" style="display:flex;align-items:center;">
                    <div style="flex:0.2;">
                        <div>窗帘</div>
                        <Button v-if="fileList.curtain.length" type="info" :loading=buttonLoading
                            @click="downloadAttach('curtain')" size="mini">下载清单{{
                                fileList.curtain.length }}</Button>
                    </div>
                    <div
                        style="flex:0.8;line-height:1.6rem;border-left:1px solid #ccc;padding:0.4rem 0">
                        <div v-for="(item) in detail.times" :key="item.id">
                            <div v-if="item.type == 2" class="list-item-cl">
                                <div style="flex:0.5rem;">{{ item.measuring_time }}</div>
                                <div style="flex:0.5rem;">{{ item.install_time }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="list-detail" style="margin-bottom:1.2rem;"
            v-if="detail.times && detail.times.length > 0">
            <div class="list-detail-header">
                <div class="list-item" style="flex:0.2;"></div>
                <div class="check" style="flex:0.4;">测量时间</div>
                <div class="check" style="flex:0.4;">安装时间</div>
            </div>
            <div class="time-item" style="border-bottom: 1px solid #ddd;">
                <div class="item-left" style="display:flex;align-items:center;">
                    <div style="flex:0.2;">
                        <div>洗衣机</div>
                        <Button v-if="fileList.washing.length" type="info" :loading=buttonLoading
                            @click="downloadAttach('washing')" size="mini">下载清单{{
                                fileList.washing.length }}</Button>
                    </div>
                    <div
                        style="flex:0.8;line-height:1.6rem;border-left:1px solid #ccc;padding:0.4rem 0">
                        <div v-for="(item) in detail.times" :key="item.id">
                            <div v-if="item.type == 3" class="list-item-cl">
                                <div style="flex:0.5rem;">{{ item.measuring_time }}</div>
                                <div style="flex:0.5rem;">{{ item.install_time }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="list-detail">
            <div class="list-detail-header">
                <div class="list-item">明细</div>
                <div class="check">核验数量</div>
            </div>
            <div v-for="(item, index) in detail.p_list" :key="item.id">
                <div class="detail-item">
                    <div class="item-left">
                        <div><span>物品名</span>：{{ item.f_title }}</div>
                        <div><span>数量</span>：{{ item.sign_num }} / {{ item.num }}</div>
                        <!-- <div><span>配送时间</span>：xxxxxxx</div> -->
                    </div>
                    <div class="item-right">
                        <div>
                            <Stepper :min='min' :max='maxList[index]' v-model="numList[index]" />
                        </div>
                        <!-- <Checkbox v-if="item.stage != 2"  v-model="checkList[index]">签收</Checkbox>
                        <Checkbox v-else :disabled="item.stage == 2" v-model="checkSuccess">已签收</Checkbox> -->
                    </div>
                </div>
                <div class="state" v-if="item.sign_num != item.num">
                    <Button type="danger" size="small" @click="showBox(item.id)">缺货</Button>
                </div>
            </div>
            <div class="note">
                <Field v-model="data.memo" type="textarea" placeholder="验收备注" :autosize="autosize">
                </Field>
            </div>
        </div>
        <div class="history" ref="history">
            <Loading v-if="loading" />
            <div v-if="!loading && detail.s_list.length">
                <div>签收记录</div>
                <Steps direction="vertical" :active="detail.s_list.length - 1">
                    <Step v-for="(item, index) in detail.s_list" :key="index">
                        <div>签收人：{{ item.signer }}</div>
                        <div>物品：{{ item.sign_content }}</div>
                        <p v-if="item.memo">备注：{{ item.memo }}</p>
                        <div>{{ item.sign_time }}</div>
                    </Step>
                </Steps>
            </div>
        </div>
        <div style="background:#FFF;">
            <div class="submit-button">
                <div class="info-button">
                    <Button round block type="info" :loading=buttonLoading @click="submit">确认验收</Button>
                </div>
            </div>
        </div>
        <div v-if="memoBox" class="memo-bg">
            <div class="memo">
                <Field v-model="supplementMemo.memo" type="textarea" placeholder="缺货备注"
                    :autosize="autosize">
                </Field>
                <div class="memo-button">
                    <div class="warning-button">
                        <Button type="info" plain @click="hideBox">取消</Button>
                    </div>
                    <div class="info-button">
                        <Button type="info" :loading=buttonLoading @click="setMemo">提交</Button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { NavBar, Field, Button, Stepper, Toast, Step, Steps, Loading } from 'vant';
export default {
    components: {
        NavBar, Field, Button, Stepper, Step, Steps, Loading
    },
    computed: {
        fileList () {
            let obj = {
                furniture: [],
                curtain: [],
                washing: [],
            }
            if (!this.detail.times || !this.detail.times.length) {
                return obj;
            }
            for (let item of this.detail.times) {
                item.purchase_file.forEach(file => {
                    if (item.type == 1) {
                        obj.furniture.push(file.url);
                    } else if (item.type == 2) {
                        obj.curtain.push(file.url);
                    } else if (item.type == 3) {
                        obj.washing.push(file.url);

                    }
                });
            }
            return obj;
        },
    },
    data () {
        return {
            autosize: { minHeight: 50, maxHeight: 200 }, value: 1,
            detail: {
                s_list: []
            },
            min: 0,
            maxList: [],
            numList: [],
            checkList: [],
            buttonLoading: false,
            loading: false,
            memoBox: false,
            checkSuccess: true,
            supplementMemo: {
                memo: '',
                f_id: '',
                token: ''
            },
            data: {
                sign_data: [],
                memo: '',
                token: '',
                f_id: ''
            }
        }
    },

    mounted () {
        this.data.token = this.$store.state.TOKEN
        this.data.f_id = this.$route.query.id
        this.supplementMemo.f_id = this.$route.query.id
        this.supplementMemo.token = this.$store.state.TOKEN
        this.getDetail(this.$route.query.id)
    },
    methods: {
        async downloadAttach (action) {
            let urls = this.fileList[action];
            for (let url of urls) {
                window.open(url)
                await this.sleep(1000)
            }
        },
        sleep (ms) {
            return new Promise(resolve => setTimeout(resolve, ms))
        },
        onClickLeft () {
            this.$router.go(-1)
        },
        getDetail (id) {
            var that = this
            this.loading = true
            this.axios.post('/api/investment/furniture_sign/detail', { token: this.$store.state.TOKEN, id: id }).then(function (res) {
                that.loading = false
                if (res.data.code == 1) {
                    that.detail = res.data.data;
                    let numberList = []
                    for (var i = 0; i < that.detail.p_list.length; i++) {
                        numberList.push(that.detail.p_list[i].num - that.detail.p_list[i].sign_num);
                        that.maxList.push(that.detail.p_list[i].num - that.detail.p_list[i].sign_num)
                    }
                    that.numList = numberList
                    console.log(that.numList)
                } else {
                    Toast.fail(res.data.msg);
                }
            }).catch(function () {
                that.loading = false
            });
        },
        onClickRight () {
            window.scrollTo({ "behavior": "smooth", "top": this.$refs.history.offsetTop });
        },
        showBox (id) {
            this.memoBox = true;
            this.supplementMemo.p_id = id
        },
        hideBox () {
            this.memoBox = false;
        },
        setMemo () {
            this.buttonLoading = true
            var that = this
            this.axios.post('/api/investment/furniture_sign/lack_sign', this.supplementMemo).then(function (res) {
                that.buttonLoading = false
                if (res.data.code == 1) {
                    Toast.success('提交成功')
                    setTimeout(() => {
                        that.hideBox()
                        that.$router.push('/list')
                    }, 1000)
                } else {
                    Toast.fail(res.data.msg);
                }
            }).catch(function () {
                that.buttonLoading = false
            });
        },
        submit () {
            this.buttonLoading = true
            let listData = []
            this.numList.forEach(item => {
                let obj = {}
                obj.num = item
                item == 0 ? obj.is_sign = 0 : obj.is_sign = 1
                listData.push(obj)
            });
            this.detail.p_list.forEach((item, index) => {
                listData[index].id = item.id
            })
            this.data.sign_data = listData.filter(function (x) {
                return x.is_sign != 0
            })
            var that = this
            this.axios.post('/api/investment/furniture_sign/sign', this.data).then(function (res) {
                that.buttonLoading = false
                if (res.data.code == 1) {
                    Toast.success('验收成功')
                    setTimeout(() => {
                        that.$router.push('/list')
                    }, 1000)
                } else {
                    Toast.fail(res.data.msg);
                }
            }).catch(function () {
                that.buttonLoading = false
            });
        }
    }
}
</script>
<style lang='scss' scoped>
.detail {
    min-height: 100vh;
    position: relative;
    padding-bottom: 6rem;
    font-size: 0.9rem;
}

.info {
    width: 80%;
    margin: 0 auto;
    text-align: left;
    color: #6d6d6d;
    line-height: 1.6rem;
    padding: 4rem 0 1rem;
}

.list-detail {
    width: 92%;
    margin: 0 auto;
    color: #6d6d6d;
    border-top: 1px solid #ccc;

    .list-detail-header {
        display: flex;
        line-height: 2rem;
        border-bottom: 1px solid #ccc;

        .list-item {
            flex: 0.7;
            border-right: 1px solid #ccc;
        }

        .check {
            flex: 0.3;
        }
    }

    .state {
        border-bottom: 1px solid #ccc;
        line-height: 2.4rem;
        padding: 0.4rem 1.2rem;
        text-align: right;
    }

    .note {
        border-bottom: 1px solid #ccc;
    }
}

.detail-item {
    display: flex;
    font-size: 0.9rem;
    border-bottom: 1px solid #ccc;

    .item-left {
        flex: 0.7;
        padding: 1rem 0rem;
        line-height: 1.6rem;
        box-sizing: border-box;
        text-align: left;
        border-right: 1px solid #ccc;
    }

    .item-right {
        flex: 0.3;
        padding: 0.5rem 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

.history {
    padding: 2rem;
    text-align: left;
    color: #666;
}

.warning-button {
    flex: 1;
    width: 100%;
}

.info-button {
    width: 100%;
    flex: 1;
}

.submit-button {
    width: 100%;
    height: 6rem;
    margin: 1.6rem auto 0;
    position: fixed;
    bottom: 0rem;
    padding: 0 16%;
    background: #fff;
    text-align: center;
    z-index: 999;
    border-top: 1px solid #ddd;
}

.memo-bg {
    width: 100%;
    min-height: 100vh;
    background: rgba($color: #000000, $alpha: .6);
    position: fixed;
    top: 0;
    z-index: 999;

    .memo {
        width: 78%;
        min-height: 15rem;
        margin: 10rem auto 0;
    }

    .memo-button {
        width: 80%;
        margin: 2rem auto;
        display: flex;
        justify-content: space-between;
    }
}

.time-item {
    text-align: center;
}

.list-item-cl {
    display: flex;
}

.list-item-box:not(:last-child) {
    border-bottom: 1px solid #ccc;
}
</style>
<style lang='scss'>
.detail .van-nav-bar {
    background: cornflowerblue;

    .van-nav-bar__title {
        color: #fff;
    }

    .van-icon {
        color: #fff;
    }

    .van-nav-bar__text {
        color: #fff;
    }
}

.memo-button .van-button--normal {
    padding: 0 30px;
}

.van-stepper {
    margin-top: 0.4rem;
}

.van-checkbox {
    margin-top: 0.6rem;
    display: flex;
    justify-content: center;

    .van-checkbox__label {
        color: #000;
        font-weight: 700;
    }
}

.van-step--finish {
    color: #9c9898;
}

.van-step--finish .van-step__circle,
.van-step--finish .van-step__line {
    background-color: cornflowerblue;
}

.van-step__icon--active,
.van-step__title--active {
    color: cornflowerblue;
}

.van-loading {
    text-align: center;
}

.van-button--block {
    width: 80%;
    margin: 1.6rem auto 0;
}
</style>